import { EventEmitter } from 'eventemitter3';

import * as rimless from '@nmbrco/rimless';

export type ThemeColorOption =
  | string
  | {
      main: string;
      light: string;
      dark: string;
      contrastText: string;
    };

export type BaseConfig = {
  companyId: string;
  partnerId: string;
  hostWindowId: string;
  sandbox?: boolean;
  theme?: {
    primary?: ThemeColorOption;
    secondary?: ThemeColorOption;
  };
  // private api
  // dev?: 'dev' | 'staging' | `${number}`;
};

export type ConstructorArgs = BaseConfig & {
  name: string;
  params?: Record<string, string>;
};

const FRAMES_VERSION = import.meta.env.FRAMES_VERSION;
const DEFAULT_ORIGIN = 'https://static.nmbr.co';

const PUBLIC_API_ORIGIN = 'https://api.nmbr.co';
const SANDBOX_API_ORIGIN = 'https://sandbox.nmbr.co';
const STAGING_API_ORIGIN = 'https://staging.nmbr.co';
const LOCAL_API_ORIGIN = 'http://localhost';

export abstract class Frame {
  /**
   * Frame subclasses must describe their `REMOTE_API`. For example:
   *
   * ```
   * protected REMOTE_API = {
   *   toggleFullscreen: () => {
   *     fullscreen.toggleFullscreen(this.iframe);
   *   },
   *   didSubmitResource: (resource: ResourceInfo) => {
   *     this.emitter.emit('didSubmitResource', resource);
   *   },
   * };
   * ```
   */
  protected abstract REMOTE_API: Record<string, unknown>;
  private static frames: Set<Frame> = new Set();

  private config: BaseConfig;
  protected iframe: HTMLIFrameElement;
  protected readonly emitter = new EventEmitter();

  private _connection?: rimless.IConnection;
  protected get connection(): Readonly<rimless.IConnection> | undefined {
    return this._connection;
  }

  public static reconnectAll(
    config: Omit<Partial<BaseConfig>, 'hostWindowId'>
  ) {
    return Promise.all(
      Frame.frames.entries().map(async ([frame]) => {
        frame.config = { ...frame.config, ...config };
        frame.disconnect();
        frame.reload();
        await frame.connect(frame.iframe);
      })
    );
  }

  constructor({ name, params, ...config }: ConstructorArgs) {
    // TODO - add args support
    // TODO - type the available names (& args)?
    this.iframe = Frame.initElement(name, config, params);
    this.config = config;
    this.connect(this.iframe).then(() => {
      // use a mutationobserver to watch for frame removal.
      // when removed, remove all observers and close the connection.
      new MutationObserver((mutations, observer) => {
        mutations.forEach((mutation) => {
          if (Array.from(mutation.removedNodes).includes(this.iframe)) {
            this.disconnect();
            this.emitter.removeAllListeners();

            observer.disconnect();
            Frame.frames.delete(this);
          }
        });
      }).observe(this.iframe.parentNode!, { childList: true });
    });

    Frame.frames.add(this);
  }

  protected async connect(iframe: HTMLIFrameElement) {
    if (this.connection) {
      return this.connection;
    }

    // run in the next event loop
    return new Promise<rimless.IConnection>((resolve) =>
      setTimeout(async () => {
        const connection = await rimless.host.connect(iframe, {
          ...this.REMOTE_API,
          ...this.remoteConfig,
          cleanup: () => this.reconnect(),
          reload: () => this.reload(),
        });

        resolve(connection);
        this._connection = connection;
      })
    );
  }

  public reconnect() {
    this.disconnect();

    return this.connect(this.iframe);
  }

  public reload() {
    this.iframe.setAttribute('src', this.iframe.getAttribute('src'));
  }

  private disconnect() {
    this.connection?.close();
    this._connection = undefined;
  }

  private static initElement(
    name: string,
    config: BaseConfig,
    params?: Record<string, string>
  ) {
    const iframe = document.createElement('iframe');

    // if we are running outside the nmbr.co domain (or "our" localhost envs),
    // we should override the origin to the nmbr.co static host.
    const scriptOrigin = new URL(import.meta.url).origin;
    const isNmbrOrigin =
      scriptOrigin.endsWith('nmbr.co') ||
      (config.dev && /^https?:\/\/localhost/.test(scriptOrigin));

    const origin = isNmbrOrigin ? scriptOrigin : DEFAULT_ORIGIN;
    const url = new URL(
      `${origin}/components-frames/${FRAMES_VERSION}/${name}`
    );

    if (params) {
      url.search = new URLSearchParams(params).toString();
    }

    iframe.src = url.href;
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = 'none';

    return iframe;
  }

  private get remoteConfig() {
    const {
      dev,
      sandbox,
      ...config
    }: Partial<
      BaseConfig & {
        dev?: 'dev' | 'staging' | `${number}`;
      }
    > = this.config;

    if (dev && dev !== 'staging' && window.location.hostname !== 'localhost') {
      // TODO - sentry
      throw new Error('development flag set but not on localhost');
    }

    // DO NOT let third-party browser code arbitrarily set an API host.
    // This would be a security risk. Instead, we use a tightly controlled set
    // of options to determine the API host, only allowing port configuration.
    let apiHost;
    if (dev === 'dev') {
      apiHost = LOCAL_API_ORIGIN;
    } else if (/\d+/.test(dev)) {
      apiHost = `${LOCAL_API_ORIGIN}:${dev}`;
    } else if (dev === 'staging') {
      apiHost = STAGING_API_ORIGIN;
    } else if (sandbox) {
      apiHost = SANDBOX_API_ORIGIN;
    } else {
      apiHost = PUBLIC_API_ORIGIN;
    }

    return { apiHost, ...config };
  }
}
